function getSuccess(message) {
  return {
    state: 'success',
    message: message || 'Successfully saved!',
    inProgress: false,
  };
}

function getError(message) {
  return {
    state: 'error',
    message: message || 'Unable to save!',
    inProgress: false,
  };
}

function getInProgress() {
  return {
    state: '',
    message: '',
    inProgress: true,
  };
}

function getFinished() {
  return {
    state: '',
    message: '',
    inProgress: false,
  };
}

const AlertService = {
  getAlert(variant, message) {
    switch (variant) {
      case 'success':
        return getSuccess(message);
      case 'error':
        return getError(message);
      case 'started':
        return getInProgress();
      case 'finished':
        return getFinished();
      default:
        return {};
    }
  },
};

export default AlertService;
