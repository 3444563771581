import zipcodes from 'zipcodes';
import { compact, trim, indexOf, find, groupBy, keys, reject } from 'lodash';
import { US_STATES, US_STATE_CODES } from './constants';

const ADDRESS_FIELDS = Object.freeze([
  'address_1',
  'address_2',
  'city',
  'state',
  'zip',
]);

export function streetAddress(address) {
  return compact([address.address_1, address.address_2]).join(' ');
}

export function cityStateZip(address) {
  const city = address.city ? `${address.city}, ` : '';
  return trim(`${city}${address.state} ${address.zip}`);
}

export function fullAddress(address) {
  const part1 = streetAddress(address);
  const part2 = cityStateZip(address);

  return compact([part1, part2]).join(', ');
}

export function isAddressField(field) {
  return indexOf(ADDRESS_FIELDS, field) > -1;
}

export function isValidStateCode(str) {
  return indexOf(US_STATE_CODES, str.toUpperCase()) > -1;
}

export function getMaxOrdinal(list) {
  const ordinals = list.map(item => item.ordinal);
  return Math.max(...ordinals);
}

export function collectZips(address) {
  let zips;
  const { city, state, zip, radius } = address;
  if (zip) {
    zips = zipcodes.radius(zip, radius || 50);
  } else {
    const locations = zipcodes.lookupByName(city, state);
    zips = locations.map(location => location.zip);
  }
  return zips;
}

export function extractCityState(str) {
  let result;
  const arr = str.split('-');
  const state = arr.pop();
  if (isValidStateCode(state)) {
    const city = arr.join(' ');
    if (city) {
      result = {
        state,
        city,
      };
    }
  }
  return result;
}

export function reformatGeoAddress(address) {
  const arr = address.split(', ');
  return arr.slice(0, arr.length - 1).join(', ');
}

export function getGeoCity(address) {
  const component = find(address.address_components, {
    types: ['locality', 'political'],
  });
  return component;
}

export function getGeoState(address) {
  const component = find(address.address_components, {
    types: ['administrative_area_level_1', 'political'],
  });
  return component;
}

export function getGeoZip(address) {
  const component = find(address.address_components, {
    types: ['postal_code'],
  });
  return component;
}

export function formatYearsLicensed(year) {
  let result;
  if (year !== null && year > 0) {
    if (year === 30) {
      result = '30+ Years Experience';
    } else {
      result = `${year} ${year > 1 ? 'Years' : 'Year'} Experience`;
    }
  }
  return result;
}

export function formatStatesLicensed(states) {
  let result;
  if (states.length > 0) {
    result = `Licensed in ${states.length} ${
      states.length > 1 ? 'States' : 'State'
    }`;
  }
  return result;
}

export function formatUrlString(str) {
  return str.replace(/\s/g, '-').toLowerCase();
}

export function sortPractices(list) {
  const practices = groupBy(list, 'category');
  let categories = keys(practices).sort();
  categories = reject(categories, c => c === 'Miscellaneous');
  categories.push('Miscellaneous');

  return {
    categories,
    practices,
  };
}

export function findStateBySlug(slug) {
  return find(US_STATES, state => formatUrlString(state.name) === slug);
}
