import qs from 'query-string';
import API from './api';

export default class ArticleApi {
  static getAll(params = {}) {
    const url = `/api/articles/?${qs.stringify(params)}`;
    return API.get(url);
  }

  static getPopular() {
    return API.get('/api/articles/popular');
  }

  static getFeatured() {
    return API.get('/api/articles/featured');
  }

  static get(id) {
    return API.get(`/api/articles/${id}`);
  }
}
