import qs from 'query-string';
import API from './api';

export default class QuoteApi {
  static create(params) {
    return API.post(`/api/quotes`, params);
  }

  static getAll(params) {
    return API.get(`/api/quotes?${qs.stringify(params)}`);
  }

  static import(id) {
    return API.post(`/api/quotes/${id}/import`);
  }
}
