import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Lens from '@material-ui/icons/Lens';

import { keys, indexOf } from 'lodash';

import { SERVICE_ITEMS } from 'helpers';

const ServiceForm = ({ value, type, onChange, ...rest }) => (
  <FormGroup {...rest}>
    {(keys(SERVICE_ITEMS[type]) || []).map(option => {
      const checked = indexOf(value, option) > -1;
      return (
        <div key={option} style={{ margin: 2 }} className="ml-0">
          <FormControlLabel
            classes={{
              label: `${checked ? 'text-black text-bold' : 'text-light-gray'}`,
              root: 'm-0',
            }}
            label={option}
            name={option}
            control={
              <Checkbox
                classes={{ root: 'p-0 mr-2' }}
                checked={checked}
                value={option}
                onChange={onChange}
                icon={<Lens htmlColor="#ccc" fontSize="small" />}
                checkedIcon={
                  <CheckCircleIcon color="secondary" fontSize="small" />
                }
              />
            }
          />
        </div>
      );
    })}
  </FormGroup>
);

ServiceForm.propTypes = {
  value: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ServiceForm;
