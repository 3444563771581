import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Button,
  ListItemText as MuiListItemText,
  Link as MuiLink,
  Avatar,
  AppBar,
  Toolbar,
  Menu,
  MenuItem as MuiMenuItem,
  List,
  Drawer,
  Divider,
  Hidden,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { AuthService } from 'services';
import Logo from 'images/logo.png';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import UpIcon from '@material-ui/icons/KeyboardArrowUp';
import MenuIcon from '@material-ui/icons/Menu';
import { NavButton } from 'components';

const MenuItem = withStyles({
  root: {
    padding: 0,
  },
})(MuiMenuItem);

const ListItemText = withStyles(theme => ({
  root: {
    minWidth: '7rem',
    padding: '6px 16px',
  },
  primary: {
    color: theme.palette.common.black,
  },
}))(MuiListItemText);

const OutlinedButton = withStyles(theme => ({
  root: {
    borderRadius: '1.25rem',
  },
  label: {
    fontSize: 12,
    [theme.breakpoints.up('lg')]: {
      fontSize: '0.875rem',
    },
  },
}))(Button);

const Link = withStyles({
  root: {
    width: '100%',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
})(MuiLink);

const BrandButton = withStyles(theme => ({
  root: {
    '& img': {
      height: '2rem',
      marginTop: 10,
      [theme.breakpoints.up('lg')]: {
        height: '2.5rem',
      },
    },
  },
}))(Button);

const SideMenu = props => {
  const { user, onLogout } = props;
  const [open, setOpen] = React.useState(false);

  function toggle(value) {
    setOpen(value);
  }

  return (
    <>
      <Button
        aria-label="Menu"
        onClick={() => toggle(true)}
        className="mr-auto"
      >
        <MenuIcon fontSize="large" />
      </Button>
      <Drawer anchor="left" open={open} onClose={() => toggle(false)}>
        <List onClick={() => toggle(false)}>
          <MenuItem dense>
            <Link component={RouterLink} to="/">
              <ListItemText primary="Home" />
            </Link>
          </MenuItem>
          <MenuItem dense>
            <Link component={RouterLink} to="/practice-areas">
              <ListItemText primary="Browse by Practice Area" />
            </Link>
          </MenuItem>
          <MenuItem dense>
            <Link component={RouterLink} to="/states">
              <ListItemText primary="Browse by State" />
            </Link>
          </MenuItem>
          <MenuItem dense>
            <Link component={RouterLink} to="/for-lawyers">
              <ListItemText primary="For Lawyers" />
            </Link>
          </MenuItem>
          <MenuItem dense>
            <Link component={RouterLink} to="/blog">
              <ListItemText primary="Blog" />
            </Link>
          </MenuItem>
          <Divider />
          {user === null ? (
            <>
              <MenuItem dense>
                <Link component={RouterLink} to="/signin">
                  <ListItemText primary="Log In" />
                </Link>
              </MenuItem>
              <MenuItem dense>
                <Link component={RouterLink} to="/signup">
                  <ListItemText primary="Sign Up" />
                </Link>
              </MenuItem>
            </>
          ) : (
            <>
              <MenuItem dense>
                <Link component={RouterLink} to="/dashboard">
                  <ListItemText primary="Dashboard" />
                </Link>
              </MenuItem>
              <MenuItem dense>
                <Link component={RouterLink} to="/account">
                  <ListItemText primary="My Account" />
                </Link>
              </MenuItem>
              <MenuItem dense>
                <Link
                  component={RouterLink}
                  to={`/profile/${user.slug}`}
                  rel="nofollow"
                >
                  <ListItemText primary="My Profile" />
                </Link>
              </MenuItem>
              <MenuItem dense onClick={onLogout}>
                <ListItemText primary="Logout" />
              </MenuItem>
            </>
          )}
        </List>
      </Drawer>
    </>
  );
};

SideMenu.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
};

SideMenu.defaultProps = {
  user: null,
};

const AccountDropdown = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { user, onLogout } = props;

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <Button
        aria-controls="account-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar alt="My Account" src={user.avatar_url} className="border" />
        {anchorEl ? <UpIcon /> : <DownIcon />}
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user.access_granted && (
          <MenuItem dense onClick={handleClose}>
            <Link component={RouterLink} to="/dashboard">
              <ListItemText primary="Dashboard" />
            </Link>
          </MenuItem>
        )}
        <MenuItem dense onClick={handleClose}>
          <Link component={RouterLink} to="/account">
            <ListItemText primary="My Account" />
          </Link>
        </MenuItem>
        <MenuItem dense onClick={handleClose}>
          <Link
            component={RouterLink}
            to={`/profile/${user.slug}`}
            className="w-100"
            rel="nofollow"
          >
            <ListItemText primary="My Profile" />
          </Link>
        </MenuItem>
        <Divider />
        <MenuItem dense onClick={onLogout}>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
    </>
  );
};

const SearchDropdown = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <>
      <NavButton
        aria-controls="search-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="nav-link"
      >
        Find an Attorney
        {anchorEl ? <UpIcon /> : <DownIcon />}
      </NavButton>
      <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="search-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem dense onClick={handleClose}>
          <Link component={RouterLink} to="/practice-areas">
            <ListItemText primary="Browse by Practice Area" />
          </Link>
        </MenuItem>
        <MenuItem dense onClick={handleClose}>
          <Link component={RouterLink} to="/states">
            <ListItemText primary="Browse by State" />
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};

AccountDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
};

const FullMenu = props => {
  const { user, onLogout } = props;
  return (
    <>
      <NavButton component={RouterLink} to="/" className="nav-link ml-auto">
        Home
      </NavButton>
      <SearchDropdown />
      <NavButton component={RouterLink} to="/for-lawyers" className="nav-link">
        For Lawyers
      </NavButton>
      <NavButton component={RouterLink} to="/blog" className="nav-link">
        Blog
      </NavButton>
      {user === null ? (
        <>
          <NavButton component={RouterLink} to="/signin" className="nav-link">
            Log In
          </NavButton>
          <OutlinedButton
            component={RouterLink}
            to="/signup"
            color="primary"
            variant="outlined"
          >
            Sign Up
          </OutlinedButton>
        </>
      ) : (
        <AccountDropdown user={user} onLogout={onLogout} />
      )}
    </>
  );
};

FullMenu.propTypes = {
  user: PropTypes.object,
  onLogout: PropTypes.func.isRequired,
};

FullMenu.defaultProps = {
  user: null,
};

class Nav extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      currentPath: props.location.pathname,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    AuthService.currentUser.subscribe(user => {
      if (this._isMounted === true) {
        this.setState({
          currentUser: user,
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ currentPath: nextProps.location.pathname });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleLogout = event => {
    event.preventDefault();
    AuthService.logout();
  };

  render() {
    const { currentUser, currentPath } = this.state;

    return (
      <div>
        <AppBar
          position="fixed"
          color="default"
          elevation={
            !currentUser ||
            currentUser.access_granted ||
            currentPath !== '/account'
              ? 3
              : 0
          }
        >
          <Container>
            <Toolbar disableGutters>
              <Hidden mdUp>
                <SideMenu user={currentUser} onLogout={this.handleLogout} />
              </Hidden>
              <BrandButton component="a" href="/">
                <img src={Logo} alt="Modern Attorney" />
              </BrandButton>
              <Hidden smDown>
                <FullMenu user={currentUser} onLogout={this.handleLogout} />
              </Hidden>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    );
  }
}

Nav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default Nav;
