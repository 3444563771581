import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Fab,
  DialogTitle as MuiDialogTitle,
  InputLabel,
  IconButton,
  FormHelperText as MuiFormHelperText,
  Grid,
  Box,
  InputAdornment,
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent,
  Menu,
  MenuList,
  MenuItem,
  ListItem as MuiListItem,
  ListItemIcon,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { amber, green } from '@material-ui/core/colors';
import {
  ValidatorForm as MuiValidatorForm,
  TextValidator as MuiTextValidator,
  SelectValidator as MuiSelectValidator,
  ValidatorComponent,
} from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import ChevronDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import { Edit as EditIcon, Trash2 as DeleteIcon } from 'react-feather';

// **************************************************************************
// DialogTitle
// **************************************************************************

export const DialogTitle = withStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
  closeButtonLarge: {
    right: theme.spacing(2),
  },
}))(props => {
  const { children, classes, onClose, closeSize, ...rest } = props;
  return (
    <MuiDialogTitle {...rest}>
      {children}
      <IconButton
        aria-label="Close"
        className={
          closeSize === 'small'
            ? classes.closeButton
            : `${classes.closeButton} ${classes.closeButtonLarge}`
        }
        onClick={onClose}
      >
        <CloseIcon fontSize={closeSize || 'small'} />
      </IconButton>
    </MuiDialogTitle>
  );
});

// **************************************************************************
// CheckBoxButton
// **************************************************************************

export const CheckboxButton = withStyles(theme => ({
  checked: {
    paddingTop: 6,
    paddingBottom: 6,
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  unchecked: {
    paddingTop: 6,
    paddingBottom: 6,
    fontWeight: 500,
  },
  icon: {
    position: 'absolute',
    right: theme.spacing(2),
  },
}))(props => {
  const {
    name,
    value,
    children,
    classes,
    onClick,
    checked,
    hideIcon,
    ...rest
  } = props;
  return (
    <Button
      fullWidth
      color={checked ? 'secondary' : 'default'}
      variant={checked ? 'contained' : 'outlined'}
      classes={{
        label: 'text-transform-none',
        root: checked ? classes.checked : classes.unchecked,
      }}
      onClick={onClick(name, value)}
      {...rest}
    >
      {children}
      {!hideIcon && checked && <DoneIcon className={classes.icon} />}
    </Button>
  );
});

// **************************************************************************
// StaticLabel
// **************************************************************************

export const StaticLabel = withStyles(theme => ({
  root: {
    color: `${theme.palette.common.black} !important`,
    fontSize: '0.875rem',
    '& + *': {
      marginTop: theme.spacing(3),
    },
    '&.bold': {
      fontWeight: 700,
    },
  },
  formControl: {
    transform: 'none',
    '&::-webkit-input-placeholder': {
      opacity: '1 !important',
    },
  },
  shrink: {
    transform: 'none',
  },
  filled: {
    transform: 'none !important',
  },
  outlined: {
    transform: 'none !important',
  },
}))(InputLabel);

// **************************************************************************
// FormHelperText
// **************************************************************************

export const FormHelperText = withStyles({
  root: {
    fontStyle: 'italic',
    color: 'rgb(76,97,113,0.7)',
    fontWeight: 400,
  },
})(MuiFormHelperText);

// **************************************************************************
// SelectValidator
// **************************************************************************

const errorMessage = {
  required: 'This field is required',
  isEmail: 'Email is not valid',
  isNumber: 'Please enter number value',
  'minStringLength:5': 'This field is not valid',
  'maxStringLength:5': 'This field is not valid',
  'minStringLength:4': 'This field is not valid',
  'maxStringLength:4': 'This field is not valid',
  'minNumber:1': 'Must be greater than 0',
};

const DefaultSelectProps = {
  MenuProps: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  },
};

const getErrorMessages = validators =>
  validators.map(validator => errorMessage[validator]);

export const SelectValidator = props => {
  const { children, validators, SelectProps, ...rest } = props;
  return (
    <MuiSelectValidator
      SelectProps={{ ...DefaultSelectProps, ...SelectProps }}
      validators={validators}
      errorMessages={getErrorMessages(validators)}
      {...rest}
    >
      {children}
    </MuiSelectValidator>
  );
};

SelectValidator.propTypes = {
  children: PropTypes.node.isRequired,
  validators: PropTypes.array,
  SelectProps: PropTypes.shape({}),
};

SelectValidator.defaultProps = {
  validators: [],
  SelectProps: {},
};

// **************************************************************************
// TextValidator
// **************************************************************************

export const TextValidator = props => {
  const { validators, ...rest } = props;
  return (
    <MuiTextValidator
      validators={validators}
      errorMessages={getErrorMessages(validators)}
      {...rest}
    />
  );
};

TextValidator.propTypes = {
  validators: PropTypes.array,
};

TextValidator.defaultProps = {
  validators: [],
};

export const ValidatorForm = React.forwardRef((props, ref) => (
  <MuiValidatorForm ref={ref} {...props} />
));

// **************************************************************************
// EditButton
// **************************************************************************

export const EditButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    padding: 0,
    minWidth: 'auto',
    minHeight: 'auto',
    width: 24,
    height: 24,
    boxShadow: 'none',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(props => (
  <Fab {...props} aria-label="Edit" size="small">
    <EditIcon size={16} color="#616161" />
  </Fab>
));

// **************************************************************************
// PasswordVisiblityAdornment
// **************************************************************************

export const PasswordVisiblityAdornment = ({ visible, onClick, ...rest }) => (
  <InputAdornment position="end" {...rest}>
    <IconButton
      aria-label="Toggle password visibility"
      onClick={onClick}
      style={{ backgroundColor: 'transparent' }}
    >
      {visible ? (
        <VisibilityIcon color="inherit" />
      ) : (
        <VisibilityOffIcon color="disabled" />
      )}
    </IconButton>
  </InputAdornment>
);

PasswordVisiblityAdornment.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

// **************************************************************************
// PasswordValidator
// **************************************************************************

export const PasswordValidator = React.forwardRef(
  ({ InputProps, ...rest }, ref) => {
    const [value, setValue] = React.useState(false);

    function handleClick() {
      setValue(!value);
    }

    return (
      <TextValidator
        ref={ref}
        type={value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <PasswordVisiblityAdornment visible={value} onClick={handleClick} />
          ),
          ...InputProps,
        }}
        {...rest}
      />
    );
  }
);

// **************************************************************************
// CheckBoxValidator
// **************************************************************************
export class CheckBoxValidator extends ValidatorComponent {
  render() {
    const {
      errorMessages,
      validators,
      requiredError,
      value,
      label,
      validatorListener,
      ...rest
    } = this.props;

    return (
      <>
        <FormControlLabel
          control={<Checkbox {...rest} />}
          label={
            <span>
              {label}
              {this.errorText()}
            </span>
          }
        />
      </>
    );
  }

  errorText() {
    const { isValid } = this.state;

    if (isValid) {
      return null;
    }

    return (
      <MuiFormHelperText error margin="dense">
        {this.getErrorMessage()}
      </MuiFormHelperText>
    );
  }
}

PasswordValidator.propTypes = {
  InputProps: PropTypes.shape({}),
};

PasswordValidator.defaultProps = {
  InputProps: {},
};

// **************************************************************************
// SnackBars
// **************************************************************************

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export const Snackbar = withStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing(1),
    opacity: 0.9,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))(props => {
  const { classes, open, message, variant, onClose, ...rest } = props;
  const [value, setValue] = React.useState(open);
  const Icon = variantIcon[variant];

  function handleClose() {
    setValue(!value);
    if (onClose !== undefined) {
      onClose();
    }
  }
  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={value}
      autoHideDuration={6000}
      onClose={handleClose}
      {...rest}
    >
      <MuiSnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={classes.icon} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            style={{ backgroundColor: 'transparent' }}
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
});

Snackbar.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
};

// **************************************************************************
// IconMenuItem
// **************************************************************************

const IconMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.grey[700],
    borderLeft: '5px solid transparent',
    minHeight: 'auto',
  },
  selected: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    backgroundColor: `rgba(${theme.palette.primary.main}, 0.5)`,
    borderColor: theme.palette.primary.main,
  },
}))(MenuItem);

// **************************************************************************
// IconMenuList
// **************************************************************************

export const IconMenuList = withStyles(theme => ({
  icon: {
    color: theme.palette.grey[600],
  },
  iconActive: {
    color: theme.palette.primary.main,
  },
}))(props => {
  const { classes, selected, menuItems, onClick, ...rest } = props;
  return (
    <MenuList {...rest}>
      {menuItems.map(item => {
        const active = item === selected;
        return (
          <IconMenuItem
            key={item.title}
            selected={active}
            onClick={() => onClick(item)}
          >
            <ListItemIcon
              classes={{ root: active ? classes.iconActive : classes.icon }}
            >
              {item.icon}
            </ListItemIcon>
            <span>{item.title}</span>
          </IconMenuItem>
        );
      })}
    </MenuList>
  );
});

// **************************************************************************
// SelectMenu
// **************************************************************************

const SelectButton = withStyles(theme => ({
  root: {
    padding: 0,
  },
  label: {
    textTransform: 'none',
    color: theme.palette.grey[500],
    fontWeight: 'normal',
  },
  endIcon: {
    marginLeft: 0,
  },
}))(Button);

export const SelectMenu = props => {
  const { options, selected, onChange } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(value) {
    onChange(value);
    handleClose();
  }

  return (
    <div>
      <SelectButton
        aria-controls="select-menu"
        aria-haspopup="true"
        size="large"
        onClick={handleOpen}
        endIcon={<ChevronDownIcon />}
      >
        {selected.label}
      </SelectButton>
      <Menu
        variant="menu"
        id="select-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem
            dense
            key={option.value}
            onClick={() => handleClick(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

SelectMenu.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

// **************************************************************************
// StitchedCard
// **************************************************************************

export const StitchedCard = withStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: theme.palette.action.hover,
    padding: theme.spacing(3),
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      border: `4px dashed ${theme.palette.grey[300]}`,
      top: -3,
      bottom: -3,
      left: -3,
      right: -3,
    },
  },
}))(props => {
  const { classes, children, ...rest } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      classes={{ root: classes.root }}
      {...rest}
    >
      {children}
    </Box>
  );
});

// **************************************************************************
// DeletableCard
// **************************************************************************

export const DeletableGrid = withStyles({
  root: {
    position: 'relative',
    '&:hover > div': {
      visibility: 'visible',
    },
  },
  wrap: {
    visibility: 'hidden',
    position: 'absolute',
    bottom: 15,
    right: -30,
  },
})(props => {
  const { classes, children, onDelete, buttonProps, ...rest } = props;
  return (
    <Grid classes={{ root: classes.root }} {...rest}>
      <Box color="error.main" className={classes.wrap}>
        <IconButton
          size="small"
          color="inherit"
          style={{ padding: 8 }}
          onClick={onDelete}
          {...buttonProps}
        >
          <DeleteIcon size={16} />
        </IconButton>
      </Box>
      {children}
    </Grid>
  );
});

export const PrimaryCircleIcon = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 14,
    borderRadius: '50%',
    padding: 2,
    marginTop: 8,
    backgroundColor: theme.palette.action.selected,
  },
}))(CircleIcon);

export const PrimaryListItem = withStyles(theme => ({
  root: {
    padding: `1px ${theme.spacing(1)}px`,
    color: theme.palette.grey[700],
  },
}))(({ children, ...props }) => (
  <MuiListItem {...props} style={{ alignItems: 'flex-start' }}>
    <PrimaryCircleIcon className="mr-2" />
    {children}
  </MuiListItem>
));

export const OverflowBox = withStyles(theme => ({
  root: {
    textAlign: 'center',
    '& img': {
      width: '75%',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '2.5rem',
      marginRight: '-2.5rem',
      height: '40rem',
      width: 'calc(50vw - 1rem)',
      overflow: 'hidden',
      '& img': {
        width: 'auto',
        height: '100%',
      },
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: '5rem',
      marginRight: '-5rem',
    },
  },
}))(Box);

export const ImageBox = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      '& img': {
        maxWidth: 360,
        width: '75%',
      },
    },
  },
}))(Box);

export const ImageBoxMd = withStyles(theme => ({
  root: {
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      '& img': {
        maxWidth: '75%',
      },
    },
  },
}))(Box);

export const NavButton = withStyles(theme => ({
  root: {
    position: 'relative',
    borderRadius: 0,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 3,
    color: theme.palette.common.black,
    borderBottom: '4px solid transparent',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
      fontSize: 16,
      lineHeight: 3.5,
    },
    '&:hover, &:active, &:focus': {
      color: theme.palette.common.black,
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },
}))(Button);
