import { Tooltip as MuiTooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Tooltip = withStyles({
  tooltip: {
    fontSize: 14,
  },
})(MuiTooltip);

export const TooltipBlack = withStyles(theme => ({
  tooltip: {
    padding: '5px 10px 10px',
    backgroundColor: theme.palette.common.black,
    marginBottom: 8,
  },
  arrow: {
    color: theme.palette.common.black,
  },
}))(MuiTooltip);
