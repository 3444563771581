import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const TabDense = withStyles({
  root: {
    minWidth: 'auto',
    minHeight: 'auto',
    textTransform: 'none',
  },
})(MuiTab);

export const TabsDense = withStyles(theme => ({
  root: {
    minHeight: 'auto',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
  },
}))(MuiTabs);
