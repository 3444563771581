import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Link,
  Avatar,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CustomerApi } from 'api';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import {
  ChevronLeft as PrevIcon,
  ChevronRight as NextIcon,
} from 'react-feather';

const SLIDER_WIDTH = 160;

function calcWidth(i) {
  switch (i % 5) {
    case 0:
      return SLIDER_WIDTH * 0.65;
    case 1:
      return SLIDER_WIDTH * 1;
    case 2:
      return SLIDER_WIDTH * 1.2;
    case 3:
      return SLIDER_WIDTH * 1;
    case 4:
      return SLIDER_WIDTH * 0.65;
    default:
      return 0;
  }
}

function alignClass(i) {
  switch (i % 5) {
    case 0:
      return 'mx-auto';
    case 1:
      return 'mr-auto';
    case 2:
      return 'mx-auto';
    case 3:
      return 'ml-auto';
    case 4:
      return 'mx-auto';
    default:
      return '';
  }
}

function textClass(i) {
  switch (i % 5) {
    case 0:
      return 'd-none';
    case 1:
      return 'text-rg';
    case 2:
      return 'text-lg';
    case 3:
      return 'text-rg';
    case 4:
      return 'd-none';
    default:
      return '';
  }
}

const NextButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    right: -50,
    top: 'calc(50% - 20px)',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[700],
  },
}))(props => (
  <IconButton {...props} aria-label="Next Slide">
    <NextIcon size={20} />
  </IconButton>
));

const PrevButton = withStyles(theme => ({
  root: {
    position: 'absolute',
    left: -50,
    top: 'calc(50% - 20px)',
    transform: 'translateY(-50%)',
    color: theme.palette.grey[700],
  },
}))(props => (
  <IconButton {...props} aria-label="Previous Slide">
    <PrevIcon size={20} />
  </IconButton>
));

export default class CustomerSlider extends Component {
  _isMounted = false;

  _slidesToShow = 5;

  _slidesToScroll = 1;

  _settings = {
    dots: false,
    infinite: true,
    lazyLoad: true,
    arrows: true,
    speed: 500,
    slidesToShow: this._slidesToShow,
    slidesToScroll: this._slidesToScroll,
  };

  _responsiveSettings = {
    dots: false,
    arrows: true,
    infinite: true,
    lazyLoad: true,
    speed: 500,
    slidesToShow: this._slidesToShow,
    slidesToScroll: this._slidesToShow,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      currentCustomers: [],
      offset: 0,
    };
  }

  componentWillMount() {
    this.loadCustomers();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleNext = () => {
    let { offset } = this.state;
    const { customers } = this.state;
    offset += this._slidesToScroll;
    if (offset + this._slidesToShow > customers.length) {
      offset = customers.length - this._slidesToShow;
    }

    this.setState({
      offset,
      currentCustomers: customers.slice(offset, offset + this._slidesToShow),
    });
    this.slider.slickNext();
  };

  handlePrev = () => {
    let { offset } = this.state;
    const { customers } = this.state;
    offset -= this._slidesToScroll;
    if (offset < 0) {
      offset = 0;
    }
    this.setState({
      offset,
      currentCustomers: customers.slice(offset, offset + this._slidesToShow),
    });
    this.slider.slickPrev();
  };

  loadCustomers() {
    CustomerApi.getAll().then(response => {
      if (this._isMounted) {
        this.setState({
          customers: response,
          currentCustomers: response.slice(0, this._slidesToShow),
        });
      }
    });
  }

  render() {
    const { currentCustomers, customers, offset } = this.state;
    const { eye, ...props } = this.props;

    return (
      <Box textAlign="center" {...props}>
        {eye && (
          <Hidden mdDown>
            <Box position="relative">
              <Slider
                {...this._settings}
                ref={c => {
                  this.slider = c;
                  return this.slider;
                }}
                className="mx-auto"
                style={{ width: 'calc(100% - 2rem)' }}
              >
                {currentCustomers.map((customer, i) => (
                  <Box key={customer.id}>
                    <Link
                      aria-label={`Read more about ${customer.name}`}
                      color="inherit"
                      href={`${process.env.BLOG_URL}${customer.path}`}
                      rel="noopener"
                      target="_blank"
                      style={{
                        width: `${calcWidth(i)}px`,
                        height: `${calcWidth(i)}px`,
                        marginTop: `calc((100% - ${calcWidth(i)}px)/2)`,
                      }}
                      className={`d-block ${alignClass(
                        i
                      )} link-gray text-decoration-none`}
                    >
                      <Avatar
                        alt={customer.name}
                        src={customer.image_url}
                        style={{
                          width: `${calcWidth(i)}px`,
                          height: `${calcWidth(i)}px`,
                        }}
                      />
                      <Box
                        textAlign="center"
                        className={textClass(i)}
                        whiteSpace="nowrap"
                        fontWeight={700}
                        mt={2}
                        lineHeight={1}
                      >
                        {customer.name}
                        <Box fontWeight={500}>Attorney</Box>
                      </Box>
                    </Link>
                  </Box>
                ))}
              </Slider>
              {offset + this._slidesToShow !== customers.length && (
                <NextButton onClick={this.handleNext} />
              )}
              {offset !== 0 && <PrevButton onClick={this.handlePrev} />}
            </Box>
          </Hidden>
        )}
        <Hidden lgUp={eye}>
          <Slider
            {...this._responsiveSettings}
            className="mx-auto"
            style={{ width: 'calc(100% - 2rem)' }}
          >
            {customers.map(customer => (
              <Box key={customer.id}>
                <Link
                  aria-label={`Read more about ${customer.name}`}
                  href={`${process.env.BLOG_URL}${customer.path}`}
                  rel="noopener"
                  target="_blank"
                  className="d-block link-gray text-decoration-none"
                >
                  <Avatar
                    src={customer.image_url}
                    style={{ width: SLIDER_WIDTH, height: SLIDER_WIDTH }}
                    className="mx-auto"
                  />
                  <Box
                    textAlign="center"
                    whiteSpace="nowrap"
                    fontWeight={700}
                    mt={2}
                    mb={1}
                    lineHeight={1}
                  >
                    {customer.name}
                    <Typography variant="body2">Attorney</Typography>
                  </Box>
                  <Typography variant="caption" color="primary">
                    View Profile
                  </Typography>
                </Link>
              </Box>
            ))}
          </Slider>
        </Hidden>
      </Box>
    );
  }
}

CustomerSlider.propTypes = {
  eye: PropTypes.bool,
};

CustomerSlider.defaultProps = {
  eye: true,
};
