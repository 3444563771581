import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import JssProvider from 'react-jss/lib/JssProvider';
import { getPageContext } from 'helpers';
import './styles/index.scss';

import { LinearProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Layout from './components/Layout';
import { ScrollToTop } from './components/shared';

const HomePage = lazy(() => import('./components/HomePage'));
const AuthPage = lazy(() => import('./components/AuthPage'));
const SigninPage = lazy(() => import('./components/SigninPage'));
const SignupPage = lazy(() => import('./components/SignupPage'));
const PasswordResetPage = lazy(() => import('./components/PasswordResetPage'));
const PasswordPage = lazy(() => import('./components/PasswordPage'));
const AccountPage = lazy(() => import('./components/AccountPage'));
const DashboardPage = lazy(() => import('./components/DashboardPage'));
const ProfilePage = lazy(() => import('./components/ProfilePage'));
const PracticeListPage = lazy(() => import('./components/PracticeListPage'));
const PracticePage = lazy(() => import('./components/PracticePage'));
const StateListPage = lazy(() => import('./components/StateListPage'));
const StatePage = lazy(() => import('./components/StatePage'));
const SearchPage = lazy(() => import('./components/SearchPage'));
const LawyerPage = lazy(() => import('./components/LawyerPage'));
const BlogPage = lazy(() => import('./components/BlogPage'));
const ArticlePage = lazy(() => import('./components/ArticlePage'));
const TermPage = lazy(() => import('./components/TermPage'));
const PrivacyPage = lazy(() => import('./components/PrivacyPage'));
const ErrorPage = lazy(() => import('./components/ErrorPage'));
const pageContext = getPageContext();

render(
  <JssProvider
    registry={pageContext.sheetsRegistry}
    generateClassName={pageContext.generateClassName}
  >
    <MuiThemeProvider theme={pageContext.theme}>
      <Router>
        <Suspense fallback={<LinearProgress />}>
          <ScrollToTop />
          <Switch>
            <Layout path="/" exact component={HomePage} />
            <Layout
              path="/authenticate/:auth_token"
              exact
              isPublic
              component={AuthPage}
            />
            <Layout path="/signin" exact isPublic component={SigninPage} />
            <Layout path="/signup" exact isPublic component={SignupPage} />
            <Layout
              path="/password/reset"
              exact
              isPublic
              component={PasswordResetPage}
            />
            <Layout
              path="/password/new"
              exact
              isPublic
              component={PasswordPage}
            />
            <Layout path="/account" exact isPrivate component={AccountPage} />
            <Layout
              path="/dashboard"
              exact
              isPrivate
              component={DashboardPage}
            />
            <Layout path="/profile/:id" exact component={ProfilePage} />
            <Layout path="/practice-areas" exact component={PracticeListPage} />
            <Layout path="/states" exact component={StateListPage} />
            <Layout path="/for-lawyers" exact component={LawyerPage} />
            <Layout path="/blog" exact component={BlogPage} />
            <Layout path="/blog/:id" exact component={ArticlePage} />
            <Layout path="/terms" exact component={TermPage} />
            <Layout path="/privacy" exact component={PrivacyPage} />
            <Layout path="/all-attorneys/:id" exact component={StatePage} />
            <Layout path="/:id" exact component={PracticePage} />
            <Layout path="/:id/:msa" exact component={SearchPage} />
            <Layout path="*" component={ErrorPage} />
          </Switch>
        </Suspense>
      </Router>
    </MuiThemeProvider>
  </JssProvider>,
  document.getElementById('root'),
);
