import API from './api';

export default class PlanApi {
  static create(params) {
    return API.post(`/api/plans`, params);
  }

  static cancel(id) {
    return API.put(`/api/plans/${id}/cancel`);
  }

  static restore(id) {
    return API.put(`/api/plans/${id}/restore`);
  }
}
