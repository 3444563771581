import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const Stepper = ({ steps, activeStep }) => (
  <Box px={0.25} display="flex">
    {steps.map(step => (
      <Box
        key={step}
        height={4}
        width="100%"
        mx={0.25}
        bgcolor={activeStep >= step ? 'primary.main' : 'grey.300'}
      />
    ))}
  </Box>
);

Stepper.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default Stepper;
