import React from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import {
  OutlinedInput,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

  return (
    <MenuItem
      dense
      {...itemProps}
      key={suggestion}
      selected={isHighlighted}
      style={{
        fontWeight: isSelected ? 700 : 400,
      }}
      className="py-0"
    >
      <ListItemText primary={suggestion} />
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
};

function getSuggestions(suggestions, value, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  if (inputLength === 0) {
    return showEmpty ? suggestions : [];
  }
  return suggestions.filter(suggestion => {
    const keep = suggestion.slice(0, inputLength).toLowerCase() === inputValue;

    if (keep) {
      count += 1;
    }

    return keep;
  });
}

const DropdownShift = withStyles({
  paper: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    maxHeight: 200,
    overflow: 'auto',
  },
})(props => {
  const { classes, suggestions, onChange, selectedItem, ...InputProps } = props;

  return (
    <Downshift onChange={onChange} selectedItem={selectedItem}>
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
      }) => {
        const result = getSuggestions(suggestions, inputValue, {
          showEmpty: true,
        });

        return (
          <div>
            <OutlinedInput {...getInputProps()} {...InputProps} />
            {isOpen && (
              <Paper className={classes.paper} elevation={2} square>
                <MenuList {...getMenuProps()}>
                  {result.map((suggestion, index) =>
                    renderSuggestion({
                      suggestion,
                      index,
                      itemProps: getItemProps({ item: suggestion }),
                      highlightedIndex,
                      selectedItem,
                    })
                  )}
                  {result.length === 0 && (
                    <MenuItem dense className="py-0 text-center">
                      <ListItemText primary="- Not Found -" className="p-0" />
                    </MenuItem>
                  )}
                </MenuList>
              </Paper>
            )}
          </div>
        );
      }}
    </Downshift>
  );
});

DropdownShift.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  selectedItem: PropTypes.string,
};

DropdownShift.defaultProps = {
  onChange: undefined,
  selectedItem: null,
};

export default DropdownShift;
