import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { TypographyItalic, TypographyLight } from 'components';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Questions = [
  {
    primary: 'Is their speciality relevant to my situation?',
    secondary:
      'First and foremost, ensure that the attorney’s experience and specialty aligns with your needs.',
  },
  {
    primary: 'How long have they been practicing this area of law?',
    secondary:
      'While experience isn’t the only factor that makes an attorney top-notch, knowing how many years they’ve been practicing can give you peace of mind.',
  },
  {
    primary: 'What is their track record?',
    secondary:
      'A good indicator of an attorney’s performance is how many instances they have reached the desired outcome for their clients.',
  },
  {
    primary: 'What is their attorney fee structure?',
    secondary:
      'When it comes to hiring an attorney, the most common question is "How much will this cost?" Ask your attorney if they operate using flat fees, hourly rates, or another method.',
  },
  {
    primary: 'Can they provide client testimonials?',
    secondary:
      'Client reviews and testimonials are a sure way to learn how your attorney conducts business. Simply request a few client testimonials so you can get insight into what working with the attorney will be like.',
  },
  {
    primary: 'How do they communicate with their clients?',
    secondary:
      'Communication is key to any client-attorney relationship. Each attorney has their preferred method of communication, whether that be text, email or phone.',
  },
];

export default function() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box maxWidth={900} mx="auto">
      <Box mb={3} align="center">
        <Typography variant="h2">Questions To Ask your Attorney</Typography>
      </Box>
      <Box align="center">
        <TypographyItalic variant="subtitle1" gutterBottom>
          What questions should I ask my lawyer?
        </TypographyItalic>
        <Typography variant="body2">
          Asking plenty of questions at your Initial Consultation is a sure way
          to find out if an attorney is right to take care of your legal
          affairs.
        </Typography>
      </Box>
      <Box my={3}>
        {Questions.map((question, index) => (
          <Box my={1} key={question.primary}>
            <Accordion
              expanded={expanded === `panel-${index}`}
              onChange={handleChange(`panel-${index}`)}
            >
              <AccordionSummary
                expandIcon={
                  expanded === `panel-${index}` ? (
                    <RemoveIcon color="secondary" fontSize="large" />
                  ) : (
                    <AddIcon color="secondary" fontSize="large" />
                  )
                }
              >
                <Typography variant="caption">{question.primary}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TypographyLight variant="body2">
                  {question.secondary}
                </TypographyLight>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
