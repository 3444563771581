import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export const Paragraph = props => (
  <Typography paragraph variant="body2" {...props} />
);

export const ParagraphLight = withStyles(theme => ({
  root: {
    fontSize: 18,
    lineHeight: 1,
    color: theme.palette.grey[700],
  },
}))(Paragraph);

export const TypographyItalic = withStyles({
  root: {
    fontStyle: 'italic',
  },
})(Typography);

export const TypographyILight = withStyles(theme => ({
  root: {
    fontStyle: 'italic',
    color: theme.palette.grey[700],
  },
}))(Typography);

export const TypographyStrong = withStyles({
  root: {
    fontWeight: 700,
  },
})(Typography);

export const TypographyLight = withStyles(theme => ({
  root: {
    color: theme.palette.grey[700],
  },
}))(Typography);
