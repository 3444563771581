import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { Route, Redirect } from 'react-router-dom';
import { CssBaseline, Box } from '@material-ui/core';

import { AuthService } from 'services';
import Nav from './Nav';
import Footer from './Footer';

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID);

const Layout = ({ component: Component, isPrivate, isPublic, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => {
      const { location } = matchProps;
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);

      document.title = 'Find an Attorney | Modern Attorney';
      document.getElementsByTagName('META')[4].content =
        "Search, compare and hire attorneys in your area to take care of your legal situation. With the Modern Attorney directory, you'll find a top-rated attorney that obtains the best results and accommodates your needs.";
      document.getElementsByTagName('META')[5].content = '';

      const currentUser = AuthService.currentUserValue;

      if (currentUser) {
        ReactGA.set({
          userId: currentUser.id,
        });
      }

      if (isPrivate && !currentUser) {
        return (
          <Redirect
            to={{ pathname: '/signin', state: { from: matchProps.location } }}
          />
        );
      }

      if (isPrivate && currentUser) {
        AuthService.refresh();
      }

      if (isPublic) {
        AuthService.logout(false);
      }

      return (
        <>
          <CssBaseline />
          <Nav {...matchProps} />
          <Box mt={7.5} display="flex" flexDirection="column">
            <Component {...matchProps} />
          </Box>
          <Footer />
        </>
      );
    }}
  />
);

export default Layout;

Layout.propTypes = {
  component: PropTypes.object.isRequired,
  isPrivate: PropTypes.bool,
  isPublic: PropTypes.bool,
};

Layout.defaultProps = {
  isPrivate: false,
  isPublic: false,
};
