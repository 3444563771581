import React from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import { getGeoCity, getGeoState, reformatGeoAddress } from 'helpers';
import { Box, Paper, MenuList, MenuItem } from '@material-ui/core';
import { TextValidator } from './form';

const LocationSearchInput = props => {
  const { onChange, onReset, inputProps, InputProps } = props;
  const [value, setValue] = React.useState('');
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  function handleChange(address) {
    setValue(address);
    onReset();
  }

  function handleSelect(address) {
    geocodeByAddress(address).then(results => {
      const result = results[0];
      const city = getGeoCity(result);
      const state = getGeoState(result);
      const formattedAddress = reformatGeoAddress(result.formatted_address);
      handleChange(formattedAddress);
      onChange({
        city: city ? city.long_name : null,
        state: state ? state.short_name : null,
        latitude: result.geometry.location.lat(),
        longitude: result.geometry.location.lng(),
      });
    });
  }

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={() => setScriptLoaded(true)}
      />
      {scriptLoaded && (
        <PlacesAutocomplete
          value={value}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={{
            types: ['(cities)'],
            componentRestrictions: { country: 'us' },
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <TextValidator
                inputProps={{
                  ...getInputProps({ ...inputProps }),
                }}
                {...InputProps}
              />
              <Box position="absolute" zIndex={2}>
                <Paper elevation={2}>
                  {loading && <div>Loading...</div>}
                  <MenuList>
                    {suggestions.map(suggestion => (
                      <MenuItem
                        {...getSuggestionItemProps(suggestion, { dense: true })}
                        key={suggestion.placeId}
                      >
                        <span>
                          {reformatGeoAddress(suggestion.description)}
                        </span>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Box>
            </div>
          )}
        </PlacesAutocomplete>
      )}
    </div>
  );
};

LocationSearchInput.propTypes = {
  inputProps: PropTypes.shape({}),
  InputProps: PropTypes.shape({}),
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

LocationSearchInput.defaultProps = {
  inputProps: {},
  InputProps: {},
};

export default LocationSearchInput;
