import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const ErrorBox = ({ message, ...props }) => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="flex-end"
    color="error.main"
    fontWeight={700}
    className="text-uppercase"
    {...props}
  >
    <WarningIcon className="mr-1" />
    <span>{message}</span>
  </Box>
);

ErrorBox.propTypes = {
  message: PropTypes.node.isRequired,
};

export default ErrorBox;
