import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Lens from '@material-ui/icons/Lens';
import { CheckboxButton } from 'components';

const YearsLicensedForm = ({ variant, value, onChange }) => {
  const handleChange = (name, checkedValue) => () => {
    if (checkedValue) {
      onChange({ target: { checked: true, value: checkedValue } });
    }
  };

  return (
    <FormGroup className="py-2" row={variant === 'button'}>
      {['5', '10', '20'].map(option => {
        const checked = option === value;
        return (
          <div key={option} style={{ margin: 2 }} className="ml-0">
            {variant === 'checkbox' ? (
              <FormControlLabel
                classes={{
                  label: `${checked ? 'text-black' : 'text-light-gray'}`,
                  root: 'm-0',
                }}
                label={`${option}+ years`}
                name={option}
                control={
                  <Checkbox
                    classes={{ root: 'p-0 mr-2' }}
                    checked={checked}
                    value={option}
                    onChange={onChange}
                    icon={<Lens htmlColor="#ccc" fontSize="small" />}
                    checkedIcon={
                      <CheckCircleIcon color="secondary" fontSize="small" />
                    }
                  />
                }
              />
            ) : (
              <CheckboxButton
                fullWidth
                hideIcon
                name={option}
                checked={value === option}
                onClick={handleChange}
                value={option}
              >
                {`${option}+ years`}
              </CheckboxButton>
            )}
          </div>
        );
      })}
    </FormGroup>
  );
};

YearsLicensedForm.propTypes = {
  variant: PropTypes.oneOf(['checkbox', 'button']),
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

YearsLicensedForm.defaultProps = {
  variant: 'checkbox',
};

export default YearsLicensedForm;
