import API from './api';

export default class PracticeApi {
  static getAll() {
    return API.get('/api/practice_fields');
  }

  static get(id) {
    return API.get(`/api/practice_fields/${id}`);
  }
}
