import { AuthService } from 'services';
import Cookies from 'js-cookie';

export default function authHeader() {
  const currentUser = AuthService.currentUserValue;
  let header = {
    'X-CSRF-Token': Cookies.get('CSRF-TOKEN'),
  };

  if (currentUser) {
    header = {
      ...header,
      Authorization: currentUser.auth_token,
    };
  }
  return header;
}
