import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Container,
  Grid,
  Link,
  Button,
  Box,
  IconButton as MuiIconButton,
  Typography,
} from '@material-ui/core';
import {
  Phone as PhoneIcon,
  Mail as MailOutlineIcon,
  Twitter as TwitterIcon,
} from 'react-feather';
import Logo from 'images/logo-alt.png';
import moment from 'moment';
import { NavButton } from 'components';

const IconButton = withStyles(theme => ({
  root: {
    color: theme.palette.grey[700],
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}))(MuiIconButton);

export default function Footer() {
  return (
    <Container maxWidth={false} className="bg-light-gray">
      <Container className="py-5">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Button
              to="/"
              className="footer__logo"
              aria-label="NextChapter Home"
            >
              <img
                src={Logo}
                alt="Modern Attorney"
                className="w-100"
                style={{ maxWidth: 200 }}
              />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="center" className="border-bottom">
              <NavButton href="/">Home</NavButton>
              <NavButton href="/">Find an Attorney</NavButton>
              <NavButton href="/for-lawyers">For Attorneys</NavButton>
            </Box>
            <Box textAlign="center" className="pt-3">
              <IconButton
                component="a"
                aria-label="Call us to learn more"
                href="tel:8888617122"
              >
                <PhoneIcon />
              </IconButton>
              <IconButton
                component="a"
                aria-label="Email us to learn more"
                href="mailto:hello@nextchapterbk.com"
              >
                <MailOutlineIcon />
              </IconButton>
              <IconButton
                component="a"
                aria-label="Learn more about Modern Attorney on twitter"
                href="https://twitter.com/NextChapterBK"
              >
                <TwitterIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box color="grey.A700">
              We are not a law firm, do not provide any legal services, legal
              advice or &quot;lawyer referral services&quot; and do not provide
              or participate in any legal representation.
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Container className="pb-3 text-center">
        <a href="/terms" className="link-light-gray">
          Terms of Service
        </a>
        <span className="px-2 text-light-gray">|</span>
        <a href="/privacy" className="link-light-gray">
          Privacy Policy
        </a>
        <Typography variant="body2" paragraph className="text-light-gray">
          &copy; {moment().format('YYYY')} Fastcase. All Rights Reserved
        </Typography>
      </Container>
    </Container>
  );
}

export function SmallFooter() {
  return (
    <Container
      maxWidth={false}
      className="bg-blue"
      style={{ padding: '2rem 0' }}
    >
      <Typography variant="subtitle1" align="center">
        Attorney interested in joining our network?
        <Link href="/for-lawyers" className="text-bold link-primary ml-1">
          Learn More!
        </Link>
      </Typography>
    </Container>
  );
}
