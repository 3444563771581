import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#f36f21'; // rgba(243, 111, 33, 1)
const primaryLight = '#f6803a'; // rgba(246, 128, 58, 1)
const primaryDark = '#d7580d'; // rgba(215, 88, 13, 1)
const secondary = '#00a4a4'; // rgba(0, 164, 164, 1)
const secondaryLight = '#2bc1c1'; // rgba(43, 193, 193, 1)
const secondaryDark = '#0f6666'; // rgba(15, 102, 102, 1)
const black = '#29333e'; // rgba(41, 51, 62, 1)
const white = '#f8f9fa'; // rgba(248, 249, 250, 1)
const gray = '#4F6272'; // rgba(79, 98, 114, 1)
const darkGrey = '#404e5c'; // rgba(64, 78, 92, 1)
const selected = 'rgba(243,111,33,0.1)'; // primary
const hover = '#eef4f8'; // rgba(238, 244, 248, 1)
const error = '#f44336'; // rgba(244, 67, 54, 1)

const placeholderHidden = {
  opacity: 0.42,
};

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiContainer: {
      maxWidth: 'xl',
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: white,
      },
    },
    MuiButtonBase: {
      root: {},
    },
    MuiButton: {
      root: {
        color: gray,
        fontWeight: 600,
        transition: 'all 0.4s ease',
        whiteSpace: 'nowrap',
        '&:hover': {
          backgroundColor: 'transparent',
          '&$disabled': {
            backgroundColor: 'transparent',
          },
        },
        // '&$disabled': {
        //   color: theme.palette.action.disabled,
        // },
      },
      textPrimary: {
        '&:hover': {
          color: white,
          backgroundColor: primaryDark,
        },
      },
      textSecondary: {
        '&:hover': {
          color: white,
          backgroundColor: secondaryDark,
        },
      },
      outlined: {
        '&:hover': {
          color: white,
          borderColor: secondary,
          backgroundColor: secondary,
        },
      },
      outlinedPrimary: {
        borderColor: primary,
        '&:hover': {
          color: white,
          borderColor: primary,
          backgroundColor: primary,
        },
      },
      outlinedSecondary: {
        borderColor: secondary,
        '&:hover': {
          color: white,
          borderColor: secondary,
          backgroundColor: secondary,
        },
      },
      contained: {
        boxShadow: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: primaryDark,
          color: white,
        },
      },
      containedSecondary: {
        // border: `1px solid ${secondary}`,
        '&:hover': {
          backgroundColor: secondaryDark,
          color: white,
        },
      },
      sizeSmall: {
        fontSize: 12,
        // padding: '7px 16px'
      },
      sizeLarge: {},
    },
    MuiInputLabel: {
      root: {
        '&[data-shrink=true]': {},
        '&[data-shrink=false]': {},
      },
      outlined: {
        fontSize: 18,
        transform: 'translate(14px, 18px) scale(0.875)',
        '&$marginDense': {
          fontSize: '1rem',
          transform: 'translate(14px, 12px) scale(0.875)',
        },
      },
    },
    MuiInputBase: {
      root: {},
      input: {
        'label[data-shrink=false] + $formControl &': {
          '&::-webkit-input-placeholder': placeholderHidden,
          '&::-moz-placeholder': placeholderHidden, // Firefox 19+
          '&:-ms-input-placeholder': placeholderHidden, // IE 11
          '&::-ms-input-placeholder': placeholderHidden, // Edge
        },
      },
      inputMarginDense: {
        fontSize: '0.875rem',
        lineHeight: '1.1875em',
      },
      inputMultiline: {
        minHeight: 100,
      },
      marginDense: {},
    },
    MuiSelect: {
      root: {},
      filled: {
        '&:focus': {
          backgroundColor: '#fff',
        },
      },
    },
    MuiFilledInput: {
      root: {
        transition: 'none',
        backgroundColor: '#fff !important',
        filter: 'drop-shadow(0px 0px 6px rgba(11, 19, 30, 0.107436))',
        '&$focused': {
          filter: `drop-shadow(0px 0px 3px rgba(243, 111, 33, 0.3))`,
        },
      },
      underline: {
        borderRadius: 3,
        '&:after': {
          display: 'none',
        },
        '&:before': {
          display: 'none',
        },
        '&$error': {
          border: `1px solid ${error}`,
        },
      },
      input: {
        padding: '15px 15px 19px 15px',
      },
      inputMarginDense: {
        padding: '10px 10px 14px 10px',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#f7f9fa',
        '&$focused $notchedOutline': {
          borderColor: secondary,
          borderWidth: 1,
        },
      },
      notchedOutline: {
        borderColor: '#e0e0e0',
      },
    },
    MuiTextField: {
      root: {},
    },
    MuiFormLabel: {
      root: {
        color: gray,
        '&$focused': {
          color: secondary,
        },
      },
    },
    MuiFormControlLabel: {
      root: {},
      label: {
        fontSize: '0.875rem',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1.125rem',
      },
      fontSizeLarge: {
        fontSize: '1.5rem',
      },
      fontSizeSmall: {
        fontSize: '1rem',
      },
    },
    MuiAvatar: {
      root: {
        backgroundColor: '#eef4f8',
      },
    },
    MuiDialog: {
      paper: {
        // borderRadius: 16,
      },
      paperScrollPaper: {},
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 3px 10px 0 rgba(173,179,191,0.3)',
      },
      elevation3: {
        boxShadow: '0 3px 6px 0 #ccc',
      },
    },
    MuiListItem: {},
    MuiListItemText: {
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiSlider: {
      root: {},
      rail: {
        height: 4,
      },
      track: {
        height: 4,
      },
      thumb: {
        width: 24,
        height: 24,
        marginTop: -10,
        marginLeft: -12,
        border: '4px solid #fff',
        boxShadow: '0 3px 10px 0 rgba(173,179,191,0.3)',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: primary,
        '&:hover, &:focus, &:active': {
          color: primary,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 'auto',
        '&$expanded': {
          minHeight: 'auto',
        },
      },
      content: {
        '&$expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: '0 16px 16px',
      },
    },
    MuiAutocomplete: {
      root: {},
      inputRoot: {
        paddingTop: '6px !important',
        paddingBottom: '8px !important',
        paddingRight: '50px !important',
        borderRadius: 3,
      },
      option: {
        fontSize: '0.875rem',
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette: {
    common: {
      black,
      white,
    },
    primary: {
      light: primaryLight,
      main: primary,
      dark: primaryDark,
      contrastText: '#fff',
    },
    secondary: {
      light: secondaryLight,
      main: secondary,
      dark: secondaryDark,
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: gray,
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: darkGrey,
    },
    text: {
      primary: black,
      secondary: 'rgba(41, 51, 62, 0.7)',
      disabled: darkGrey,
      hint: 'rgba(41, 51, 62, 0.5)',
      icon: 'rgba(41, 51, 62, 0.5)',
    },
    divider: 'rgba(41, 51, 62, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      active: black,
      hover,
      hoverOpacity: 0.2,
      selected,
      disabled: 'rgba(41, 51, 62, 0.3)',
      disabledBackground: 'rgba(41, 51, 62, 0.12)',
    },
  },
  typography: {
    htmlFontSize: 16,
    fontFamily: 'Lato, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: 'ivyjournal, sans-serif',
      fontWeight: 700,
      fontSize: '2.25rem',
      lineHeight: 1.2,
      marginBottom: '0.5rem',
    },
    h2: {
      fontFamily: 'ivyjournal, sans-serif',
      fontWeight: 700,
      fontSize: '1.875rem',
      lineHeight: 1.2,
      marginBottom: '0.5rem',
    },
    h3: {
      fontFamily: 'ivyjournal, sans-serif',
      fontWeight: 700,
      fontSize: '1.75rem',
      lineHeight: 1.2,
      marginBottom: '0.5rem',
    },
    h4: {
      fontFamily: 'ivyjournal, sans-serif',
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: 1.2,
      marginBottom: '0.5rem',
    },
    h5: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.33,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: 'Lato, sans-serif',
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
    },
    button: {
      fontFamily: 'Lato, arial, helvetica, sans-serif',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.125rem',
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 3,
  },
});

export default theme;
