import { BehaviorSubject } from 'rxjs';
import { AuthApi, UserApi } from 'api';

const currentUserSubject = new BehaviorSubject(
  JSON.parse(localStorage.getItem('currentUser'))
);

function signup(user) {
  return UserApi.create(user).then(response => {
    localStorage.setItem('currentUser', JSON.stringify(response));
    currentUserSubject.next(response);

    return response;
  });
}

function login(email, passsword) {
  return AuthApi.authenticate({ email, password: passsword }).then(response => {
    localStorage.setItem('currentUser', JSON.stringify(response));
    currentUserSubject.next(response);

    return response;
  });
}

function authenticate(authToken) {
  return AuthApi.authenticateByToken({ auth_token: authToken }).then(
    response => {
      localStorage.setItem('currentUser', JSON.stringify(response));
      currentUserSubject.next(response);

      return response;
    }
  );
}

function refresh() {
  return AuthApi.refresh().then(response => {
    localStorage.setItem('currentUser', JSON.stringify(response));
    currentUserSubject.next(response);

    return response;
  });
}

function logout(reload = true) {
  localStorage.removeItem('currentUser');
  currentUserSubject.next(null);
  if (reload) {
    window.location.reload();
  }
}

const AuthService = {
  authenticate,
  signup,
  login,
  logout,
  refresh,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
};

export default AuthService;
