import React from 'react';
import { SvgIcon } from '@material-ui/core';
import {
  Mail as MailIcon,
  PhoneCall as PhoneIcon,
  Smartphone as MobileIcon,
  Users as UsersIcon,
  DollarSign as DollarIcon,
  Video as VideoIcon,
  Coffee as CoffeeIcon,
  Zap as ZapIcon,
  Lock as LockIcon,
  File as FileIcon,
} from 'react-feather';
import CarIcon from '@material-ui/icons/DirectionsCar';

const NcIcon = props => (
  <SvgIcon {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 30 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          stroke="null"
          d="m7.58201,-0.99998l-7.582,0l8.588,12.60108l-8.532,12.51892l7.582,0l8.533,-12.51892l-8.589,-12.60108z"
          id="arrow-first"
        />
        <path
          stroke="null"
          d="m17.58201,-0.99998l-7.582,0l8.588,12.60109l-8.532,12.51891l7.582,0l8.533,-12.51891l-8.589,-12.60109z"
          id="arrow-last"
        />
      </g>
    </svg>
  </SvgIcon>
);

export const NextChapterIcon = (
  <SvgIcon className="mr-1">
    <svg viewBox="0 0 30 25" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          stroke="null"
          fill="#f8f9fa"
          d="m7.58201,-0.99998l-7.582,0l8.588,12.60108l-8.532,12.51892l7.582,0l8.533,-12.51892l-8.589,-12.60108z"
          id="arrow-first"
        />
        <path
          stroke="null"
          fill="#f8f9fa"
          d="m17.58201,-0.99998l-7.582,0l8.588,12.60109l-8.532,12.51891l7.582,0l8.533,-12.51891l-8.589,-12.60109z"
          id="arrow-last"
        />
      </g>
    </svg>
  </SvgIcon>
);

export const SERVICE_ITEMS = Object.freeze({
  communications: {
    Email: <MailIcon size={16} />,
    Text: <MobileIcon size={16} />,
    Phone: <PhoneIcon size={16} />,
    'Remote Meetings': <UsersIcon size={16} />,
  },
  payments: {
    '$0 Down': <DollarIcon size={16} />,
    'Accepts Credit Card': <DollarIcon size={16} />,
    'Accepts ApplePay': <DollarIcon size={16} />,
    'Accepts Venmo': <DollarIcon size={16} />,
    'Accepts Checks / Cash': <DollarIcon size={16} />,
    'Accepts Bitcoin': <DollarIcon size={16} />,
    'Accepts Cash App': <DollarIcon size={16} />,
  },
  consultations: {
    'Free Consultation': <DollarIcon size={16} />,
    'Phone Meetings': <PhoneIcon size={16} />,
    'In-Person Meetings': <UsersIcon size={16} />,
    'Travel to Client': <CarIcon fontSize="small" />,
    'Video Conference Meetings': <VideoIcon size={16} />,
    'Coffee Shop Meetings': <CoffeeIcon size={16} />,
  },
  technologies: {
    'NextChapter Power User': <NcIcon fontSize="small" />,
    Texting: <PhoneIcon size={16} />,
    'Secure Online Portal': <LockIcon size={16} />,
    'Modern Technology': <ZapIcon size={16} />,
    'Credit Report': <FileIcon size={16} />,
  },
});

export const US_STATES = Object.freeze(require('json-loader!states.yml'));

export const US_STATE_CODES = Object.freeze(US_STATES.map(state => state.code));
export const US_STATE_NAMES = Object.freeze(US_STATES.map(state => state.name));

export const GRAPH_COLORS = [
  '#f7783b',
  'rgb(247,120,59,0.5)',
  '#ffe1d1',
  '#00a4a4',
  'rgb(0,164,164,0.5)',
  '#03a9f4',
  'rgb(3,169,244,0.5)',
  '#ef5350',
  '#ef9a9a',
  '#fff176',
];

export const TAGS = window.TAGS
  ? JSON.parse(window.TAGS.replace(/&quot;/g, '"').replace(/&#39;/g, "'"))
  : [];

delete window.TAGS;

export const BASIC_LISTING_ITEMS = [
  'Directory Listing Page',
  'Dashboard Analytics',
  'Access to Qualified Leads',
  'Import Qualified Leads into NextChapter',
  'Elite Support building your profile',
  'Modern Attorney Badge for Website',
  'Modern Attorney Award Candidate',
];

export const PREMIUM_LISTING_ITEMS = BASIC_LISTING_ITEMS.concat([
  'Premium Placement in Attorney Directory',
  'Access to Dedicated Account Manager',
  'Multiple Links to Your Website',
]);
