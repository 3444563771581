import React from 'react';
import PropTypes from 'prop-types';

const MapIcon = ({ label, fontSize }) => (
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M23.5 45.0417L22.9453 45.8738C23.2812 46.0977 23.7188 46.0977 24.0547 45.8738L23.5 45.0417ZM23.5 45.0417C24.0547 45.8738 24.055 45.8736 24.0553 45.8733L24.0564 45.8726L24.0597 45.8704L24.071 45.8628L24.1124 45.8348C24.1482 45.8105 24.2002 45.775 24.2673 45.7285C24.4015 45.6355 24.5962 45.4988 24.8428 45.3207C25.3359 44.9646 26.037 44.4426 26.8768 43.7739C28.5549 42.4376 30.795 40.509 33.0385 38.1409C37.4845 33.4479 42.125 26.8395 42.125 19.5834C42.125 14.6437 40.1627 9.90637 36.6699 6.41351C33.177 2.92064 28.4397 0.958374 23.5 0.958374C18.5603 0.958374 13.823 2.92064 10.3301 6.41351C6.83727 9.90637 4.875 14.6437 4.875 19.5834C4.875 26.8395 9.51551 33.4479 13.9615 38.1409C16.205 40.509 18.4451 42.4376 20.1232 43.7739C20.963 44.4426 21.6641 44.9646 22.1572 45.3207C22.4038 45.4988 22.5985 45.6355 22.7327 45.7285C22.7998 45.775 22.8518 45.8105 22.8876 45.8348L22.929 45.8628L22.9403 45.8704L22.9436 45.8726L22.9447 45.8733C22.945 45.8736 22.9453 45.8738 23.5 45.0417Z"
        fill="#F36F21"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fill="white"
        fontWeight="700"
        fontSize={fontSize}
        fontFamily="Arial, Helvetica, sans-serif"
      >
        {label}
      </text>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="47" height="47" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

MapIcon.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.number,
};

MapIcon.defaultProps = {
  label: '',
  fontSize: 18,
};

export default MapIcon;
