import API from './api';

export default class AuthApi {
  static authenticate(params) {
    return API.post(`/api/authenticate`, params);
  }

  static authenticateByToken(params) {
    return API.post(`/api/authenticate_by_token`, params);
  }

  static refresh() {
    return API.get(`/api/auth/refresh`);
  }
}
