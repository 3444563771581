import qs from 'query-string';
import API from './api';

export default class AnalyticsApi {
  static create(params) {
    return API.post(`/api/analytics`, params);
  }

  static analyticsReport(params) {
    return API.get(`/api/analytics_report?${qs.stringify(params)}`);
  }

  static trendsReport(params) {
    return API.get(`/api/trends_report?${qs.stringify(params)}`);
  }
}
