import API from './api';

export default class PasswordApi {
  static reset(params) {
    return API.post(`/api/password/reset`, params);
  }

  static create(params) {
    return API.post(`/api/password`, params);
  }

  static update(params) {
    return API.put(`/api/password`, params);
  }
}
