import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  FormControl,
  MenuItem,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PracticeApi, MsaApi } from 'api';
import { AlertService } from 'services';
import { formatUrlString } from 'helpers';
import zipcodes from 'zipcodes';
import { range } from 'lodash';
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
  Snackbar,
  LocationSearchInput,
} from 'components';

const SearchFormContainer = withStyles(theme => ({
  root: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: '#EFF4F8',
    borderBottomLeftRadius: 'min(10vw, 130px)',
    borderBottomRightRadius: 'min(10vw, 130px)',
  },
}))(Box);

class SearchForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      practices: [],
      loading: true,
      query: {},
      alert: {
        state: '',
        message: '',
        inProgress: false,
      },
    };
  }

  componentWillMount() {
    this.load();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleChange = event => {
    event.persist();
    this.setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        [event.target.name]: event.target.value,
      },
    }));
  };

  handlePracticeChange = event => {
    this.setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        id: event.target.value,
      },
    }));
  };

  handleLocationChange = location => {
    this.setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        ...location,
      },
    }));
  };

  handleResetLocation = () => {
    this.setState(prevState => ({
      ...prevState,
      query: {
        ...prevState.query,
        city: null,
        state: null,
        latitude: null,
        longitude: null,
      },
    }));
  };

  handleSubmit = event => {
    event.preventDefault();
    const { query } = this.state;
    if (query.zip) {
      const result = zipcodes.lookup(query.zip);
      if (result) {
        this.submit({ ...query, ...result });
      } else {
        this.submit(query);
      }
    } else {
      const result = zipcodes.lookupByName(query.city, query.state);
      if (result.length) {
        this.submit({ ...query, ...result[0] });
      } else {
        this.alert('error', 'Please enter valid zip code!');
      }
    }
  };

  submit(query) {
    MsaApi.search({ zip: query.zip }).then(response => {
      if (response.length) {
        const [msa] = response;
        const { history, location } = this.props;
        history.push({
          pathname: formatUrlString(`/${query.id}/${msa.slug}`),
          state: {
            from: location.pathname,
            query,
          },
        });
      } else {
        this.alert('error', 'Please enter valid zip code!');
      }
    });
  }

  load() {
    PracticeApi.getAll().then(response => {
      if (this._isMounted) {
        this.setState(prevState => ({
          ...prevState,
          practices: response,
          loading: false,
        }));
      }
    });
  }

  alert(variant, message) {
    const alert = AlertService.getAlert(variant, message);
    this.setState({ alert });
  }

  render() {
    const { practices, query, alert, loading } = this.state;

    return (
      <Box>
        {alert.state && (
          <Snackbar
            open={alert.message.length > 0}
            variant={alert.state}
            message={alert.message}
          />
        )}
        <Box textAlign="center">
          <Typography variant="h1">Find A Modern Attorney</Typography>
          <Typography variant="subtitle1">
            Search top-rated attorneys that fit into your lifestyle
          </Typography>
        </Box>
        {loading && (
          <Box align="center">
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <Box className="mt-5">
            <ValidatorForm instantValidate={false} onSubmit={this.handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4} lg={3}>
                  <FormControl fullWidth>
                    <SelectValidator
                      variant="filled"
                      name="id"
                      value={query.id || ''}
                      onChange={this.handlePracticeChange}
                      validators={['required']}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                    >
                      <MenuItem dense disabled value="">
                        Choose Speciality
                      </MenuItem>
                      {practices.map(practice => (
                        <MenuItem dense key={practice.id} value={practice.slug}>
                          {practice.name}
                        </MenuItem>
                      ))}
                    </SelectValidator>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={5}>
                      <FormControl fullWidth>
                        <LocationSearchInput
                          onChange={this.handleLocationChange}
                          onReset={this.handleResetLocation}
                          InputProps={{
                            variant: 'filled',
                            placeholder: 'City, State',
                            fullWidth: true,
                            validators:
                              query.zip || (query.city && query.state)
                                ? []
                                : ['required'],
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Box className="text-gray">OR</Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <TextValidator
                          variant="filled"
                          id="zip"
                          name="zip"
                          placeholder="Zip Code"
                          value={query.zip || ''}
                          onChange={this.handleChange}
                          validators={
                            query.city && query.state
                              ? []
                              : [
                                  'required',
                                  'isNumber',
                                  'minStringLength:5',
                                  'maxStringLength:5',
                                ]
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth>
                        <SelectValidator
                          variant="filled"
                          name="radius"
                          value={query.radius || ''}
                          onChange={this.handleChange}
                          SelectProps={{
                            displayEmpty: true,
                          }}
                        >
                          <MenuItem dense disabled value="">
                            Within
                          </MenuItem>
                          {range(5, 55, 5).map(radius => (
                            <MenuItem dense key={radius} value={radius}>
                              {`${radius} miles`}
                            </MenuItem>
                          ))}
                        </SelectValidator>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} lg={3} className="mx-auto">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    <span style={{ padding: 5 }}>Find Attorney</span>
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Box>
        )}
      </Box>
    );
  }
}

SearchForm.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export { SearchFormContainer, SearchForm };
