import API from './api';

export default class UserApi {
  static search(params) {
    return API.post(`/api/users/search`, params);
  }

  static getSummary() {
    return API.get(`/api/users/summary`);
  }

  static get(id) {
    return API.get(`/api/users/${id}`);
  }

  static create(params) {
    return API.post(`/api/users/create_user`, params);
  }

  static update(params) {
    return API.put(`/api/users/${params.id}`, params);
  }

  static notify(params) {
    return API.post(`/api/users/${params.id}/notify`, params);
  }

  static current() {
    return API.get('/api/users/current');
  }

  static delete(id) {
    return API.delete(`/api/users/${id}`);
  }
}
