import request from 'axios';
import { authHeader } from 'helpers';

class Api {
  static getReqOptions(url, method, data, opts) {
    const o = opts || {};
    const options = {
      headers: authHeader(),
      url,
      method,
      responseType: 'json',
      ...o,
    };
    if (method === 'PUT' || method === 'POST') {
      options.data = data;
    }
    return options;
  }

  static get(url, options) {
    const reqOptions = Api.getReqOptions(url, 'GET', null, options);
    return request(reqOptions)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }

  static put(url, data, options) {
    const reqOptions = Api.getReqOptions(url, 'PUT', data, options);
    return request(reqOptions)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }

  static post(url, data, options) {
    const reqOptions = Api.getReqOptions(url, 'POST', data, options);
    return request(reqOptions)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }

  static delete(url, options) {
    const reqOptions = Api.getReqOptions(url, 'DELETE', null, options);
    return request(reqOptions)
      .then(response => response.data)
      .catch(error => Promise.reject(error));
  }
}

export default Api;
