import API from './api';

export default class MsaApi {
  static search(params) {
    return API.post(`/api/msas/search`, params);
  }

  static get(id) {
    return API.get(`/api/msas/${id}`);
  }
}
